import { Controller } from "@hotwired/stimulus"
import { Editor } from "@tiptap/core"
import StarterKit from "@tiptap/starter-kit"

export default class TiptapEditorController extends Controller {
  declare readonly element: HTMLElement

  editor!: Editor

  connect() {
    const editorElement = this.element.querySelector(".tiptap-editor")
    const field = editorElement?.getAttribute("data-field")
    const fieldLabel = editorElement?.getAttribute("data-field-label")
    const locale = editorElement?.getAttribute("data-locale")

    if (field && locale && editorElement) {
      const textarea = document.querySelector<HTMLTextAreaElement>(
        `textarea[data-field="${field}"][data-locale="${locale}"]`
      )
      if (!textarea) return

      this.editor = new Editor({
        element: editorElement,
        extensions: [StarterKit],
        content: textarea.value
      })

      this.editor.on("update", ({ editor }) => {
        textarea.value = editor.getHTML()
      })

      this.addMenu(editorElement, fieldLabel, locale)
    }
  }

  disconnect() {
    this.editor.destroy()
  }

  addMenu(editorElement: Element, fieldLabel: string, locale: string) {
    // Add menu
    // Create menu wrapper
    const menuWrapper = document.createElement("div")
    menuWrapper.className = "menu-wrapper" // Add class for styling if necessary

    const title = document.createElement("div")
    title.textContent = `${fieldLabel} (${locale})`
    menuWrapper.appendChild(title)

    const buttonBold = document.createElement("button")
    buttonBold.textContent = "Bold"
    buttonBold.addEventListener("click", (event) => {
      event.preventDefault()
      this.editor.chain().focus().toggleBold().run()
    })
    menuWrapper.appendChild(buttonBold)

    const buttonItalic = document.createElement("button")
    buttonItalic.textContent = "Italic"
    buttonItalic.addEventListener("click", (event) => {
      event.preventDefault()
      this.editor.chain().focus().toggleItalic().run()
    })
    menuWrapper.appendChild(buttonItalic)

    const buttonsHeading = [1, 2, 3].map((level) => {
      const button = document.createElement("button")
      button.textContent = `H${level}`
      button.addEventListener("click", (event) => {
        event.preventDefault()
        this.editor.chain().focus().toggleHeading({ level }).run()
      })
      return button
    })
    buttonsHeading.forEach((button) => menuWrapper.appendChild(button))

    // Button for Unordered List (ul)
    const buttonUL = document.createElement("button")
    buttonUL.textContent = "UL"
    buttonUL.addEventListener("click", (event) => {
      event.preventDefault()
      this.editor.chain().focus().toggleBulletList().run()
    })
    menuWrapper.appendChild(buttonUL)

    // Button for Ordered List (ol)
    const buttonOL = document.createElement("button")
    buttonOL.textContent = "OL"
    buttonOL.addEventListener("click", (event) => {
      event.preventDefault()
      this.editor.chain().focus().toggleOrderedList().run()
    })
    menuWrapper.appendChild(buttonOL)

    // Button to Clear Styles
    const buttonClearStyles = document.createElement("button")
    buttonClearStyles.textContent = "×"
    buttonClearStyles.addEventListener("click", (event) => {
      event.preventDefault()
      // Clear styles - Remove bold, italic, links, and lists, etc.
      this.editor.chain().focus().unsetAllMarks().clearNodes().run()
    })
    menuWrapper.appendChild(buttonClearStyles)

    // Append menu wrapper to editor container
    editorElement.prepend(menuWrapper)

    this.editor.on("update", () => {
      buttonBold.classList.toggle("is-active", this.editor.isActive("bold"))
      buttonItalic.classList.toggle("is-active", this.editor.isActive("italic"))
      buttonsHeading.forEach((button, index) => {
        button.classList.toggle(
          "is-active",
          this.editor.isActive("heading", { level: index + 1 })
        )
      })
      buttonUL.classList.toggle("is-active", this.editor.isActive("bulletList"))
      buttonOL.classList.toggle(
        "is-active",
        this.editor.isActive("orderedList")
      )
    })
  }
}
