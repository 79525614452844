import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.initializeAssetGroupsDropdown()
  }

  initializeAssetGroupsDropdown = async () => {
    const dropdown = document.querySelector(
      "#asset_group_kind"
    ) as HTMLSelectElement

    if (!dropdown) return

    const updateDropdown = () => {
      document.querySelectorAll(".conditional-input").forEach((e) => {
        e.classList.add("hidden")
      })

      const dropdownValue = dropdown?.value

      switch (dropdownValue) {
        case "image":
        case "audio":
          document.querySelectorAll(".globalized").forEach((e) => {
            e.classList.remove("hidden")
          })
          break
        case "localized_image":
        case "localized_audio":
          document.querySelectorAll(".localized").forEach((e) => {
            e.classList.remove("hidden")
          })
          break
      }
    }

    updateDropdown()
    dropdown.addEventListener("change", updateDropdown)
  }
}
