import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "assetGroupsContainer",
    "template",
    "addAssetGroupButton",
    "assetGroupFields"
  ]

  connect() {
    this.setActiveAssetOnLoad()
  }

  addAssetGroup(event) {
    event.preventDefault()

    // Clone the content of the template
    const clonedTemplate =
      this.templateTarget.content.cloneNode(true).firstElementChild

    // Calculate new index based on the number of .asset-group-fields elements
    const newIndex = this.assetGroupsContainerTarget.querySelectorAll(
      ".asset-group-fields"
    ).length

    // Replace 'INDEX' with the newIndex in the cloned template's innerHTML
    const newInnerHTML = clonedTemplate.innerHTML.replace(/INDEX/g, newIndex)
    clonedTemplate.innerHTML = newInnerHTML

    // Insert the cloned template before the Add Asset Group button
    this.assetGroupsContainerTarget.insertBefore(
      clonedTemplate,
      this.addAssetGroupButtonTarget
    )
  }

  removeAssetGroup(event) {
    if (event.target.classList.contains("remove-asset-group")) {
      event.preventDefault()
      const parentDiv = event.target.closest(".asset-group-fields")
      const destroyInput = parentDiv.querySelector('input[name$="[_destroy]"]')
      if (destroyInput) {
        destroyInput.value = "1"
      }
      parentDiv.style.display = "none"
    }
  }

  setActiveImage(event) {
    const clickedAsset = event.target.closest(".asset-group")
    if (clickedAsset) {
      event.preventDefault()
      const parentDiv = clickedAsset.closest(".asset-group-fields")
      const hiddenField = parentDiv.querySelector(
        'input[name$="[asset_group_id]"]'
      )
      if (hiddenField) {
        hiddenField.value = clickedAsset.dataset.value
      }

      // Remove the 'active' class from all .asset-group elements within the current .asset-group-fields
      const siblingAssets = parentDiv.querySelectorAll(".asset-group")
      siblingAssets.forEach((img) => img.classList.remove("active"))

      // Add 'active' class to the clicked asset
      clickedAsset.classList.add("active")
    }
  }

  setActiveAssetOnLoad() {
    this.assetGroupFieldsTargets.forEach((group) => {
      const hiddenFieldValue = group.querySelector(
        'input[name$="[asset_group_id]"]'
      ).value
      const matchingAsset = Array.from(
        group.querySelectorAll(".asset-group")
      ).find((img) => img.dataset.value === hiddenFieldValue)

      if (matchingAsset) {
        matchingAsset.classList.add("active")
      }
    })
  }
}
